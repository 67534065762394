import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './Components/Navbar';
import MainPage from './Components/MainPage';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Services from './Components/Services';
import ScrollToTop from './Components/ScrollToTop';
import About from './Components/About';

function App() {
  return (
    <div className="App overflow-hidden site" >
      <div className='site-content'>
        <BrowserRouter>
          <ScrollToTop />
          <NavBar />
          <Switch>
            <Route exact path='/' component={MainPage} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/services' component={Services} />
            <Route exact path='/about' component={About} />
          </Switch>

        </BrowserRouter>
      </div>
      <Footer />

    </div>
  );
}

export default App;
