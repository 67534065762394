import React, { Component } from "react";
import {
  Button,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarBrand,
} from "reactstrap";
import Drawer from "@mui/material/Drawer";
import logo from "../images/logo.png";
import { withRouter } from "react-router-dom";
import SideBar from "./SideBar";

class NavBar extends Component {
  state = {
    isOpen: false,
    changeColor: true,
    windowWidth: 0,
  };

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    window.addEventListener("resize", this.updateWindowDimension);
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }

  updateWindowDimension = (e) => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  listenScrollEvent = (e) => {
    if (window.scrollY < 100) {
      this.setState({ changeColor: true });
    } else {
      this.setState({ changeColor: false });
    }
  };

  toggle = (e) => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <div style={{ marginBottom: 125 }} className="fixed-top ">
        <div>
          <div className="bg-light">
            {this.state.windowWidth > 992 && (
              <div>
                <div className={this.state.windowWidth > 1200 && "container"}>
                  <Navbar expand="lg">
                    <NavbarBrand href="/">
                      <img
                        src={logo}
                        style={{ maxHeight: 100 }}
                        className="img-fluid "
                        alt="allsafe commercial precast"
                      />
                    </NavbarBrand>
                    <Nav
                      className="ml-auto "
                      navbar
                      style={{ transition: "all .5s ease" }}
                    >
                      <NavItem>
                        <NavLink
                          className="navlink"
                          style={{
                            cursor: "default",
                            fontWeight: `bold`,
                            // fontSize: 14
                          }}
                          onClick={this.handleClick}
                        >
                          <i
                            className="fa-solid fa-map-location mr-3 regularNavLink"
                            style={{ fontSize: 20 }}
                          >
                            {" "}
                          </i>
                          <div
                            className="position-absolute"
                            style={{ top: 45, marginLeft: 30, fontSize: 12 }}
                          >
                            <p style={{ color: "rgb(14,35,87)" }}>
                              <span>Level 40 </span>
                              <br />
                              <span>140 Williams St </span>
                              <br />

                              <span>Melbourne VIC 3000</span>
                            </p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className="navlink"
                          style={{
                            marginLeft: 100,
                            cursor: "default",
                            fontWeight: `bold`,
                            // fontSize: 14
                          }}
                          onClick={this.handleClick}
                        >
                          <i
                            className="fa-solid fa-paper-plane mr-3 regularNavLink"
                            style={{ fontSize: 20 }}
                          ></i>
                          <div
                            className="position-absolute"
                            style={{ top: 45, marginLeft: 30, fontSize: 12 }}
                          >
                            <p style={{ color: "rgb(14,35,87)" }}>
                              <span> +61 3 909 88676</span> <br />
                              <span>admin@allsafeprecast.com.au</span>
                            </p>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className="navlink"
                          style={{
                            marginLeft: 160,
                            cursor: "default",
                            fontWeight: `bold`,
                            // fontSize: 14
                          }}
                          onClick={this.handleClick}
                        >
                          <i
                            className="fa-solid fa-clock mr-3 regularNavLink"
                            style={{ fontSize: 20 }}
                          ></i>
                          <div
                            className="position-absolute"
                            style={{ top: 45, marginLeft: 30, fontSize: 12 }}
                          >
                            <p style={{ color: "rgb(14,35,87)" }}>
                              <span> Monday - Friday</span> <br />
                              <span>8.00 am to 5.00 pm</span>
                            </p>
                          </div>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Nav className="ml-auto ">
                      <NavItem>
                        <NavLink
                          className="navlink"
                          style={{
                            marginLeft: 150,
                            cursor: "default",
                            fontWeight: `bold`,
                          }}
                          onClick={this.handleClick}
                        ></NavLink>
                      </NavItem>

                      <Button
                        href="tel:+61 3 909 88676"
                        size="md"
                        className="my-3 py-2 border-0"
                        style={{ width: 150, backgroundColor: "darkorange" }}
                      >
                        <i className="fa-solid fa-phone pr-3"></i>
                        CALL NOW
                      </Button>
                    </Nav>
                  </Navbar>
                </div>
                <SideBar />
              </div>
            )}
          </div>

          <div>
            <Navbar
              expand="lg"
              className={this.state.windowWidth < 992 ? "bg-light" : "p-0"}
            >
              {this.state.windowWidth < 992 && (
                <>
                  <NavbarBrand href="/">
                    <img
                      src={logo}
                      style={{ maxHeight: 100 }}
                      className="img-fluid "
                      alt="allsafe commercial precast"
                    />
                  </NavbarBrand>
                  <NavbarToggler onClick={this.toggle}>
                    <i className="fa-solid fa-bars fa-2x"></i>
                  </NavbarToggler>
                </>
              )}

              {this.state.windowWidth < 992 && (
                <Drawer
                  anchor="left"
                  open={this.state.isOpen}
                  onClose={this.toggle}
                >
                  <Nav
                    className="ml-auto pl-3 mt-4"
                    navbar
                    style={{ transition: "all .5s ease", width: "200px" }}
                  >
                    <NavItem>
                      <NavLink
                        className="navlink"
                        style={{
                          cursor: "pointer",
                          fontWeight: `bold`,
                          fontSize: 14,
                        }}
                        onClick={this.handleClick}
                        href="/"
                      >
                        <i className="fa-solid fa-house pr-3 py-2"></i>
                        HOME
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="navlink"
                        style={{
                          cursor: "pointer",
                          fontWeight: `bold`,
                          fontSize: 14,
                        }}
                        href="/about"
                      >
                        <i className="fa-solid fa-building pr-3 py-2"></i>
                        ABOUT
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="navlink"
                        style={{
                          cursor: "pointer",
                          fontWeight: `bold`,
                          fontSize: 14,
                        }}
                        href="/services"
                      >
                        <i className="fa-solid fa-keyboard pr-3 py-2"></i>
                        SERVICES
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="navlink"
                        style={{
                          cursor: "pointer",
                          fontWeight: `bold`,
                          fontSize: 14,
                        }}
                        href="/contact"
                      >
                        <i className="fa-solid fa-paper-plane pr-3 py-2"></i>
                        CONTACT US
                      </NavLink>
                    </NavItem>

                    <Button
                      href="tel:+61 3 909 88676"
                      size="md"
                      className="my-3 py-2 border-0"
                      style={{ width: 150, backgroundColor: "darkorange" }}
                    >
                      <i className="fa-solid fa-phone pr-3"></i>
                      CALL NOW
                    </Button>
                  </Nav>
                </Drawer>
              )}
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(NavBar);
