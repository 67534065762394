import React, { Component } from 'react'
import { Container, Row, Col, Button, } from 'reactstrap'
import ServicesTop from './ServicesTop'
import residential from '../images/residential-construction.jpg'
import commercial from '../images/commercial-construction.jpg'
import Slide from 'react-reveal'
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet";

class Services extends Component {

    handleClick = () => {
        this.props.history.push('/contact')
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>AllSafe Commercial Precast - Our Services </title>
                    <meta name="description" content="AllSafe Commercial Precast - Our Services" />
                </Helmet>
                <ServicesTop />
                <div>
                    <Container>
                        <Row className='pt-5 pb-3 '>
                            <Col>
                                <Fade bottom>
                                    <h1 >When it comes to your precast project, expertise is everything.</h1>
                                    <p>
                                        Thank you for your interest in Allsafe Concrete Precast.
                                        We are proud to provide our customers with high-quality products,
                                        designed withstand deterioration and last for decades to come.

                                </p>
                                    <p>
                                        Since 1985, we have worked diligently to engineer the best precast concrete products possible to our customers at an affordable price.
                                        We are committed to providing exceptional service and guaranteed satisfaction.
                                        If you have any questions regarding any of our precast products or would like a custom quote, please contact us.
                                </p>
                                </Fade>
                            </Col>
                        </Row>
                        <Row className='pb-5  border-bottom'>

                            <Col sm='6' xs='12' className='py-3'>
                                <img src={commercial} className='img-fluid' alt='All precast concrete panel installs' />
                            </Col>

                            <Col sm='6' xs='12' className='py-3'>
                                <div className='text-left'>
                                    <ul >
                                        <li className='py-2'>All precast concrete panel installs</li>
                                        <li className='py-2'>All concrete panel repairs, caulking, plates & lugs patching</li>
                                        <li className='py-2'>All concrete repairs & rectifications</li>
                                        <li className='py-2'>Grouting, grout tubes & plate welding</li>
                                        <li className='py-2'>Prop removal</li>
                                    </ul>
                                </div>

                            </Col>

                        </Row>
                        <Slide left>
                            <Row className='border-bottom py-3'>

                                <Col md='6'  >

                                    <h3 className='text-muted'>Domestic Services</h3>
                                    <h5> Creating precast concrete structures which are safe,
                                        look good and are built on time and on budget is what we do.</h5>
                                    <Button className='my-3' onClick={() => this.handleClick()} outline color='dark'>INQUIRE NOW</Button>

                                </Col>
                                <Col md='6'>
                                    <img className='services-link' src={residential} alt='Domestic Services' />
                                </Col>

                            </Row>
                        </Slide>
                        <Slide right>
                            <Row className='border-bottom py-3'>
                                <Col md='6'  >
                                    <h3 className='text-muted'>Commercial Services</h3>
                                    <h5>Functional and aesthetically pleasing to match your project’s design.</h5>
                                    <Button className='my-3' onClick={() => this.handleClick()} outline color='dark'>INQUIRE NOW</Button>
                                </Col>
                                <Col md='6'>
                                    <img className='services-link' src={commercial} alt='Commercial Services' />
                                </Col>
                            </Row>
                        </Slide>
                        {/* <Slide left>
                            <Row className=' py-5'>
                                <Col  >
                                    <p>Want to make changes to your existing structure? We can help you redesign your kitchen, living room,
                                    bathroom and more! From additions to complete renovations, we can do it all.</p>
                                    <Button className='my-3' onClick={() => this.handleClick()} outline color='dark'>INQUIRE NOW</Button>

                                </Col>
                            </Row>
                        </Slide> */}


                    </Container>
                    <Slide right>
                        <Row className='h-100 bg-dark text-light' style={{
                            minHeight: '40vh',
                        }}>

                            <Col className=' my-auto '>
                                <div
                                    className=' w-100 text-center px-5 py-5'>
                                    <h1>
                                        We Are Here To Help
                                        </h1>
                                    <p className='px-5 py-3 mx-5 font-italic'>
                                        Interested in our services, but unsure if the price is right?
        Contact us today to set up a meeting for a free quote!
                            </p>
                                    <Button className='my-3' onClick={() => this.handleClick()} outline color='light'>CONTACT US</Button>

                                </div>
                            </Col>
                        </Row>
                    </Slide>
                </div>
            </div>
        )
    }
}
export default Services