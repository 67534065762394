import React, { Component } from 'react'
import { Form, FormGroup, Label, Input, Row, Container, Col, Alert } from 'reactstrap';
import Slide from 'react-reveal/Slide';
import classnames from 'classnames'
import axios from 'axios'
import { Helmet } from "react-helmet";
import { withScriptjs, GoogleMap, withGoogleMap, Marker } from "react-google-maps"



function Map() {
    return (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: -37.815787867323714, lng: 144.9587607909281 }}>
            <Marker position={{ lat: -37.815787867323714, lng: 144.9587607909281 }} />
        </GoogleMap>
    )
}


const WrappedMap = withScriptjs(withGoogleMap(Map))




class Contact extends Component {

    state = {
        name: '',
        email: '',
        phonenumber: '',
        subject: '',
        message: '',
        errors: {},
        success: ''
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.success !== prevState.success && this.state.success !== '') {
            setTimeout(this.resetSuccess, 5000)
        }
    }
    resetSuccess = () => {
        this.setState({
            success: ''
        })
    }

    valueChanged = (e) => {
        this.setState({
            [e.target.name]: e.target.value,

        })
    }

    submitForm = (e) => {
        e.preventDefault();
        let data = this.state
        axios.post('/contact', data)
            .then(res => {
                this.setState({
                    success: res.data.success,
                    errors: {},
                    name: '',
                    email: '',
                    phonenumber: '',
                    subject: '',
                    message: ''
                })
            })
            .catch(err => {
                this.setState({
                    errors: err.response.data,
                })
            })
    }
    render() {
        return (
            <div className='mt-5 py-5' id='contact'>
                <div >
                    <Helmet>
                        <title>Contact - AllSafe Commercial Precast - GET A FREE QUOTE OR REQUEST A CALL BACK NOW</title>
                        <meta name="description" content="Contact AllSafe Commercial Precast Melbourne Today!" />
                    </Helmet>
                </div>
                <Container className=' py-5' >
                    <Slide right>
                        <h1 className='text-center pt-5'>Have a question? We have the answers. </h1>
                        <Row>
                            <Col>
                                <h5 className='text-center pt-3'>Our friendly customer service team are ready to assist you. Fill out the form down below and we will get back to you as soon as possible.</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ height: '30vh' }}>
                                <WrappedMap
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div className='w-100 h-100 mapContainer' />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                            </Col>
                        </Row>
                        <Row className='pt-5 py-3'>
                            <Col md='4'>
                                <div style={{ fontSize: 18 }}>
                                    <p className='p-0 m-0' style={{ color: 'rgb(14,35,87)' }} >Our Address:</p>
                                    <p className='font-weight-bold' style={{ color: 'rgb(14,35,87)' }}>
                                        <span >Level 40 </span>
                                        <br />
                                        <span>140 Williams St </span>
                                        <br />

                                        <span>Melbourne VIC 3000</span>
                                    </p>
                                </div>
                                <div style={{ fontSize: 18 }}>
                                    <p className='m-0'>Phone Number:</p>
                                    <p className='font-weight-bold' style={{ color: 'rgb(14,35,87)' }}>
                                        +61 3 909 88676
                                    </p>
                                </div>

                                <div style={{ fontSize: 18 }}>
                                    <p className='m-0'>Email:</p>
                                    <p className='font-weight-bold' style={{ color: 'rgb(14,35,87)' }}>
                                        admin@allsafeprecast.com.au
                                    </p>
                                </div>


                                <div style={{ fontSize: 18 }}>
                                    <p className='m-0'>Work Hours:</p>
                                    <p className='font-weight-bold' style={{ color: 'rgb(14,35,87)' }}>
                                        <span > Monday - Friday</span> <br />
                                        <span>8.00 am to 5.00 pm</span>
                                    </p>
                                </div>




                            </Col>
                            <Col>
                                <Form className='mx-3 mt-2 mb-3 p-5' style={{ backgroundColor: 'whitesmoke', borderRadius: 20 }} onSubmit={this.submitForm}>
                                    <FormGroup className='text-left' >
                                        <Label for="name">Name*</Label>
                                        <Input type="text" value={this.state.name} name="name" placeholder="Name"
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.name })}
                                        />
                                        <div className='invalid-feedback'>{this.state.errors.name}</div>
                                    </FormGroup>

                                    <FormGroup className='text-left' >
                                        <Label for="phonenumber" >Phone Number*</Label>
                                        <Input type='text' value={this.state.phonenumber} name="phonenumber" placeholder="Phone Number"
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.phonenumber })}
                                        />
                                        <div className='invalid-feedback'>{this.state.errors.phonenumber}</div>
                                    </FormGroup>

                                    <FormGroup className='text-left' >
                                        <Label for="email">Email*</Label>
                                        <Input type="email" value={this.state.email} name="email" placeholder="Email"
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.email })}
                                        />
                                        <div className='invalid-feedback'>{this.state.errors.email}</div>
                                    </FormGroup>

                                    <FormGroup className='text-left' >
                                        <Label for="subject">Subject*</Label>
                                        <Input type='select' value={this.state.subject} name="subject" placeholder="Subject"
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.subject })}
                                        >
                                            <option hidden>Subject</option>
                                            <option value='Get a Quote'>Get a Quote</option>
                                            <option value='General'>General</option>
                                            <option value='Request a Callback'>Request a Callback</option>
                                        </Input>
                                        <div className='invalid-feedback'>{this.state.errors.subject}</div>
                                    </FormGroup>

                                    <FormGroup className='text-left' >
                                        <Label for="exampleText">Your message*</Label>
                                        <Input type="textarea" rows={10} value={this.state.message} name="message" placeholder='Drop your message here'
                                            onChange={this.valueChanged}
                                            className={classnames('', { 'is-invalid': this.state.errors.message })}
                                        />
                                        <div className='invalid-feedback'>{this.state.errors.message}</div>
                                    </FormGroup>

                                    {this.state.success !== '' && this.state.success !== undefined &&
                                        <Alert color='success'>{this.state.success}</Alert>

                                    }
                                    <FormGroup>
                                        <Input type='submit' className='bg-success border-0 ' value='Send' />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>


                    </Slide>
                </Container>
            </div>
        )
    }
}
export default Contact