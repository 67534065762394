import React, { Component } from 'react'
import { Button, } from 'reactstrap'
import construction from '../images/construction.jpg'




export default class Project extends Component {
    render() {
        return (
            <div className='position-relative' style={{ backgroundImage: `url(${construction})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className='h-100' style={{ backgroundColor: 'rgb(0, 0, 0, 0.3)' }}>
                    <div className='text-light position-relative  py-5 container'>
                        <h1>
                            Got A Project?</h1>
                        <p>
                            Have a question? Need assistance with how Allsafe can meet your needs? That's what we are here for. Fill out the form on our contact page or simply click the button below to have a chat with us.
                    </p>
                        <Button
                            href='/contact'
                            size='md' className='my-3 py-2 border-0' style={{ width: 150, backgroundColor: 'darkorange' }}>
                            <i className="fa-solid fa-phone pr-3"></i>
                            CONTACT US
                    </Button>
                    </div>
                </div>
            </div>
        )
    }
}
