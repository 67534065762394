import React, { Component } from 'react'
import { Row, Col, } from 'reactstrap'

export default class Options extends Component {
    render() {
        return (
            <div className='container'>
                <Row className='my-5 px-5'>
                    <Col md='4' sm='12'>
                        <div className='text-center'>
                            <i class="fa-solid fa-gauge-high fa-5x regularNavLink"></i>
                            <h3 className='mt-3'>Performance</h3>
                            <p>
                                When it comes to performance, Allsafe Concrete Precast provides the precast concrete that stands up to the rigors of the environment.
                            </p>
                        </div>
                    </Col>
                    <Col md='4' sm='12'>
                        <div className='text-center'>
                            <i class="fa-solid fa-compass-drafting fa-5x regularNavLink"></i>
                            <h3 className='mt-3'>Full Service
</h3>
                            <p>
                                As a full service supplier we provide a broad range of solutions for today's construction industry.
                                We manufacture and supply concrete panels, provice panel repairs, caulking, plates & lugs patching.
                            </p>
                        </div>

                    </Col>
                    <Col md='4' sm='12'>
                        <div className='text-center'>
                            <i class="fa-solid fa-helmet-safety fa-5x regularNavLink"></i>
                            <h3 className='mt-3'>Safety</h3>
                            <p>
                                Doing things safely is what we live every day and it is the first value we operate by at Allsafe Commercial Precast.
                            </p>
                        </div>
                    </Col>

                </Row>
            </div>
        )
    }
}
