import React, { Component } from "react";
import logo from "../images/logo.png";
import social from "../images/social.png";

class TraderLogo extends Component {
  render() {
    return (
      <div
        className="my-3 mx-auto "
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img src={logo} className="img-fluid mx-3" style={{ maxHeight: 100 }} />
        <img
          src={social}
          className="img-fluid mx-3"
          style={{ maxHeight: 100 }}
        />
      </div>
    );
  }
}
export default TraderLogo;
