import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div >
                <footer className="page-footer font-small mdb-color pt-4 border-top">
                    <div className=''>
                        <div className=" mx-3 text-center text-md-left">

                            <div className="row text-center text-md-left mt-3 pb-3">

                                <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">AllSafe Concrete Precast</h6>
                                    <p>We provide our high-quality fences to assure the efficient execution of your project.
                                        Exceeding your expectations is our chief goal.
</p>
                                </div>

                                <hr className="w-100 clearfix d-md-none" />

                                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Useful links</h6>
                                    <p>
                                        <a href="/">Home</a>
                                    </p>
                                    <p>
                                        <a href="/about">About</a>
                                    </p>
                                    <p>
                                        <a href="/services">Services</a>
                                    </p>
                                    <p>
                                        <a href="/contact">Contact</a>
                                    </p>
                                </div>

                                <hr className="w-100 clearfix d-md-none" />

                                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>

                                    <p style={{ color: 'rgb(14,35,87)' }}>
                                        <span style={{ width: '300px', whiteSpace: `nowrap` }} >
                                            <i className="fas fa-home mr-3 regularNavLink " > </i>
                                            <span >Level 40
                                             </span>
                                        </span>
                                        <br />
                                        <span style={{ paddingLeft: '35px' }}>140 Williams St</span>
                                        <br />
                                        <span style={{ paddingLeft: '35px' }}>Melbourne VIC 3000</span>
                                    </p>

                                    <p>
                                        <a href='mailto:info@allsafeprecast.com.au'><i className="fas fa-envelope mr-3 regularNavLink"></i> info@allsafeprecast.com.au</a>
                                    </p>
                                    <p>
                                        <a href='tel:+61 3 909 88676'><i className="fas fa-phone mr-3 regularNavLink"></i> +61 3 909 88676</a></p>
                                </div>

                            </div>

                            <hr />

                            <div className="row ">

                                <div className="col-md-12 text-center">
                                    <p >© 2020 Copyright:
                                        <a href="https://allsafeprecast.com.au/">
                                            <strong> AllSafe Commercial Precast</strong>
                                        </a>
                                    </p>

                                </div>


                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}
