import React, { Component } from "react";
import Options from "./Options";
import GetStarted from "./GetStarted";
import Landing from "./Landing";
import OurServices from "./OurServices";
import { Helmet } from "react-helmet";
import Project from "./Project";
import TraderLogo from "./TraderLogo";

class MainPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>
            ALLSAFE COMMERCIAL PRECAST MELBOURNE | CHEAP AND ACCREDITED PRECAST
            SERVICES{" "}
          </title>
          <meta
            name="description"
            content="ALLSAFE COMMERCIAL PRECAST MELBOURNE | CHEAP AND ACCREDITED PRECAST SERVICES"
          />
        </Helmet>
        <Landing />
        <Options />
        <Project />
        <OurServices />
        <GetStarted />
        <TraderLogo />
      </div>
    );
  }
}
export default MainPage;
