import React, { Component } from "react";
import AboutTop from "./AboutTop";
import { Row, Col, Container } from "reactstrap";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import TraderLogo from "./TraderLogo";

class About extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>
            About Us | Allsafe Commercial Precast Melbourne | UP TO 50% OFF FOR
            FIRST TIMERS{" "}
          </title>
          <meta
            name="description"
            content="About Us |Allsafe Commercial Precast Melbourne"
          />
        </Helmet>
        <AboutTop />
        <Container>
          <Row className="pt-5 pb-3 ">
            <Col>
              <Fade bottom>
                <div>
                  <h1>Creating Opportunity</h1>
                  <p>
                    At Allsafe Commercial, we are driven by a profound mission:
                    to provide meaningful employment opportunities for
                    individuals impacted by the justice system. We understand
                    the challenges and stigmas that can accompany reintegration
                    into society, and we believe in the power of employment to
                    transform lives.
                  </p>

                  <p>
                    Our organisation is dedicated to creating an encouraging and
                    supportive environment where individuals can not only
                    develop valuable skills but also regain confidence and
                    purpose. We recognise the potential within each person, and
                    through tailored training programs and mentorship, we
                    empower our employees to thrive professionally and
                    personally.
                  </p>
                  <p>
                    Beyond job placement, we are committed to supporting our
                    employees in their journey toward successful reintegration
                    into society. We understand the challenges and complexities
                    of life beyond the justice system, which is why we provide
                    comprehensive support services aimed at addressing various
                    needs, such as counselling, housing, and transportation.
                  </p>
                  <p>
                    At Allsafe Commercial, we believe in second chances and the
                    inherent dignity of every individual. By providing
                    employment opportunities and holistic support, we are not
                    just building a workforce; we are building a brighter future
                    for our employees and our communities.
                  </p>
                  <p>
                    We specialise in the supply and installation of precast
                    panels, concrete crack injection, floor levelling, and
                    specialised concrete rectification services for commercial
                    and civil projects across Victoria.
                  </p>
                  <p>
                    Our expertise in precast concrete ensures that your design
                    choices are optimal from day one. We are more than just a
                    precast manufacturer. Our comprehensive services cover
                    everything from design drawings to manufacturing, transport
                    and logistics, installation, and construction completion.
                    This holistic approach ensures seamless project execution
                    and superior results.
                  </p>

                  <p>
                    Committed to social responsibility, we provide employment
                    opportunities to socially disadvantaged individuals, with a
                    particular focus on those who have experienced the justice
                    system. We believe in the power of second chances and strive
                    to make a positive impact on our community.
                  </p>
                  <p>
                    What sets us apart is our unwavering commitment to
                    reliability and trustworthiness. Our core values drive us to
                    find innovative solutions for our customers' every need,
                    ensuring satisfaction and excellence in every project we
                    undertake.
                  </p>
                </div>

                <TraderLogo />
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default About;
