import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'

export default class GetStarted extends Component {
    state = {
        windowWidth: 0,
    }

    componentDidMount() {
        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);
    }

    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }


    render() {
        return (
            <div className='container'>
                <h1 className='text-center'>Ready To Get Started?</h1>
                <Row className={this.state.windowWidth < 768 ? ' h-100 text-center my-4 py-4 px-5' : `h-100 text-center my-4 py-4 pl-5 ml-2`} >
                    <Col md='3' sm='12'>

                        <i className="fas fa-phone-volume fa-3x regularNavLink"></i>
                        <h3 className='pt-3'>1. Contact</h3>
                        <p>Call or quote online and we will arrange our team to contact you.
                            <a href='/contact'> Request a Call Back. </a>

                            Phone shy? There’s no need to call, you can  <a href='/contact'>Enquire online!</a></p>
                    </Col>
                    <Col xs='1' className='my-auto d-none d-md-block '>
                        <i className="fas fa-arrow-right fa-3x regularNavLink"></i>
                    </Col>
                    <Col md='3' sm='12'>
                        <i className="far fa-comments fa-3x regularNavLink"></i>
                        <h3 className='pt-3'>2. We'll have a quick chat</h3>
                        <p>We’ll then have a quick chat to you over the phone or by replying to your online quote about your
                             project.</p>
                    </Col>
                    <Col xs='1' className='my-auto d-none d-md-block'>
                        <i className="fas fa-arrow-right fa-3x regularNavLink"></i>
                    </Col>
                    <Col md='3' sm='12'>
                        <i className="fas fa-calculator fa-3x regularNavLink"></i>
                        <h3 className='pt-3'>3. We'll send you a free quote</h3>
                        <p>Once we have gathered all of the measurements and
                            discovered your needs, we will then formulate a free quote for you within 48 hours of coming out to see you</p>
                    </Col>
                </Row>
            </div>
        )
    }
}
