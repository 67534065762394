import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import commercial from '../images/commercial.jpeg'
import residential from '../images/residential.jpeg'


export default class OurServices extends Component {
    render() {
        return (
            <div className='my-4 container'>
                <Row className=''>

                    <Col className='text-center pb-3' md='6' sm='12'>
                        <img className='services-image' src={commercial} alt='allsafe waterproofing domestic waterproofing ' />
                        <h3 className='py-3'>Commercial Services</h3>
                        <p>
                            Are you looking for an estimate for a new commercial system? Repairing or replacing an existing Commercial concrete panels?
                            If you answered YES to any of these, we can help!
                    </p>
                    </Col>


                    <Col className='text-center pb-3' md='6' sm='12'>
                        <img className='services-image' src={residential} alt='allsafe waterproofing commercial waterproofing ' />
                        <h3 className='py-3'>Domestic Services</h3>
                        <p>
                            Are you building a new home?

Replacing an old system?

Do you need a service technician for repair work?

Are you looking to prevent future septic system failures?

If you answered YES to any of these, we can help!
                        </p>
                    </Col>

                </Row>
            </div>
        )
    }
}
