import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'

class ServicesTop extends Component {
    render() {
        return (
            <div className='services' style={{
                marginTop: 100,
                height: '30vh',
                overflow: 'hidden',
                backgroundPosition: `center`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `cover`,
                position: `relative`,
                minHeight: `350px`
            }}>
                <div style={{
                    backgroundColor: `rgb(0, 0, 0, 0.4)`,
                    minHeight: '350px',
                    height: '30vh',
                }}>
                    <Container className='  h-100 ' >
                        <Row className='  h-100'>
                            <Col className=' my-auto'>
                                <div className=' text-center text-light'>
                                    <h1 className='font-weight-bold'>
                                        Services
                                </h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
export default ServicesTop