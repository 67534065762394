import React, { Component } from 'react'
import {
    Col,
    Row,
    Button,
    Container

} from 'reactstrap';
import Zoom from 'react-reveal/Zoom';
import image1 from '../images/precast-page.jpeg'
import image2 from '../images/Precast-Concrete.jpeg'
import image3 from '../images/dream-concrete.jpeg'
import image4 from '../images/hivis.webp'


const pages = [
    {
        title: `Welcome to Allsafe Commercial Precast`,
        subTitle: 'Leading precast concrete suppliers and concrete rectification specialists',
        buttonText: 'About Us',
        buttonLink: '/about',
        imageSrc: image1,
        imageAltText: 'We offer a wide range of standard as well as engineered precast solutions',
        backgroundColor: 'rgb(0, 0, 0, 0.2)'
    }, 
    {
        title: 'Creating Opportunity',
        subTitle: 'Beyond Job Placement',
        buttonText: 'Learn More',
        buttonLink: '/about',
        imageSrc: image4,
        imageAltText: '',
        backgroundColor: 'rgb(0, 0, 0, 0.2)'
    },
{
    title: 'You dream it. We’ll cast it.',
    subTitle: 'We are dedicated to satisfying customer needs',
    buttonText: `Let's Talk`,
    buttonLink: '/contact',
    imageSrc: image3,
    imageAltText: 'Precast construction provides for significantly shortened construction schedules.',
    backgroundColor: 'rgb(0, 0, 0, 0.2)'
}
]


class Landing extends Component {
    state = {
        offsetY: 0,
        windowWidth: 0,

    }
    componentDidMount() {
        this.setState({
            windowWidth: window.innerWidth,
        })
        window.addEventListener('resize', this.updateWindowDimension);
        window.addEventListener('scroll', this.listenToScroll)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimension);
        window.removeEventListener('scroll', this.listenToScroll)
    }
    listenToScroll = () => {
        this.setState({
            offsetY: window.pageYOffset
        })

    }
    updateWindowDimension = (e) => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }

    render() {
        return (
            <div
                id="landingCarousel" className="carousel slide carousel-fade " data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#landingCarousel" data-slide-to="0" className="active"></li>
                    {pages.map((page, index) => (index !== 0 &&
                        <li data-target="#landingCarousel" data-slide-to={index}></li>
                    ))}
                </ol>
                <div className="carousel-inner">
                    {pages.map((page, index) => (
                        <div key={index} className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
                            <img style={{ height: '80vh', minHeight: '540px', objectFit: 'cover', top: 0, bottom: 0 }} className=" w-100 position-absolute img-fluid" src={page.imageSrc} alt={page.imageAltText} />
                            <div className="overlay text-center w-100 position-relative"
                                style={{
                                    backgroundColor: page.backgroundColor,
                                    top: 0
                                }}>
                                <Container className=' w-100 h-100  '>
                                    <Row className=' my-auto h-100 '>
                                        <Col className=' my-auto '>
                                            <div className=' text-center text-light position-relative '>
                                                <h1>
                                                    {page.title}
                                                </h1>
                                                <p style={{ fontSize: 18 }}>{page.subTitle} </p>
                                                <Zoom >
                                                    <Button
                                                        href={page.buttonLink}
                                                        className='border-0 px-3 py-2' size='md' style={{ backgroundColor: 'darkorange', borderRadius: 30 }} >
                                                        <span className='font-weight-bold' style={{ fontSize: 15, }}>
                                                            {page.buttonText}
                                                        </span>
                                                    </Button>
                                                </Zoom>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    ))}

                </div>
                <a className="carousel-control-prev "
                    href="#landingCarousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#landingCarousel"
                    role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        )
    }
}
export default Landing