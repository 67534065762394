import React, { Component } from 'react'
import { UncontrolledTooltip } from 'reactstrap'

export default class SideBar extends Component {
    render() {
        return (
            <div>
                <div className="icon-bar px-3">

                    <div className='my-5 text-center'>
                        <a href='/' >
                            <i style={{ color: 'darkorange', cursor: 'pointer', fontSize: 22 }} className="fa-solid fa-house " id='Home'></i>
                        </a></div>
                    <div className='my-5 text-center'>
                        <a href='about'>
                            <i style={{ color: 'darkorange', cursor: 'pointer', fontSize: 22 }} className="fa-solid fa-building " id='About'></i>
                        </a>
                    </div>
                    <div className='my-5 text-center'>
                        <a href='/services'>
                            <i style={{ color: 'darkorange', cursor: 'pointer', fontSize: 22 }} className="fa-solid fa-keyboard " id='Services'></i>
                        </a></div>
                    <div className='my-5 text-center'>
                        <a href='/contact'>
                            <i style={{ color: 'darkorange', cursor: 'pointer', fontSize: 22 }} className="fa-solid fa-paper-plane " id='Contact'></i>
                        </a> </div>


                    <UncontrolledTooltip
                        placement="right"
                        target="Home"
                    >
                        Home
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                        placement="right"
                        target="About"
                    >
                        About
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                        placement="right"
                        target="Services"
                    >
                        Services
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                        placement="right"
                        target="Contact"
                    >
                        Contact
                    </UncontrolledTooltip>
                </div>
            </div >
        )
    }
}
